import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi7';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Czyszczenie tapicerki skórzanej w Krakowie</h2>
        <p>
          Skóra naturalna to drogi materiał, z którym należy obchodzić się
          bardzo ostrożnie. Pomimo swojej wytrzymałości podlega okresowym
          zanieczyszczeniom, na powierzchni mogą pojawić się także
          mikropęknięcia i zarysowania.
        </p>
        <h3>Etapy kompleksowego czyszczenia mebli skórzanych:</h3>
        <ul>
          <li>Wykonaj diagnostykę i sprawdź stan materiału;</li>
          <li>
            Usuń kurz, brud i płytkę nazębną za pomocą specjalnych środków
            czyszczących;
          </li>
          <li>Użyj odplamiaczy, aby oczyścić materiał z brudu i plam;</li>
          <li>
            Użyj środka czyszczącego, aby przywrócić pierwotny kolor i zapach
            materiału;
          </li>
          <li>
            Renowacja mebli skórzanych odbywa się przy użyciu specjalnych
            delikatnych produktów.
          </li>
        </ul>
        <p>
          W wyniku tak kompleksowej pracy w domu, skórzane meble przywrócą im
          pierwotny wygląd, aromat i czystość.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga7() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga7',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga7;
