import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi4';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Mattress washing in Krakow</h2>
        <p>
          No matter how much we try to keep the mattresses clean, over time the
          material loses its original color and gets dirty. No one is safe from
          spilling coffee, tea or wine in bed. Samurai Dry Cleaning offers
          urgent cleaning of the mattress from dirt using professional equipment
          and ecological products from the professional series.
        </p>
        <h3>Why is dry cleaning of mattresses necessary?</h3>
        <p>
          During use, the surface of the mattress absorbs particles of sweat,
          spilled liquids, food odors and much more. In addition,
          microorganisms, mites and dandruff get into the fabric. All this
          requires periodic cleaning. However, this cannot be done with
          household cleaning products. Imagine how much sweat the mattress
          fabric absorbs (especially in the hot summer). It is impossible to
          protect yourself against hair, animal hair, and especially dust. Dry
          cleaning of the mattress is also mandatory for the child to prevent
          the development of an allergic reaction to dust mites.
        </p>
        <h3>
          Cleaning mattresses at home allows you to solve several problems at
          once:
        </h3>

        <ul>
          <li>Remove unpleasant odor from the mattress;</li>
          <li>Clean it from stains;</li>
          <li>
            Treatment of seats, ceiling upholstery and carpets with foam that
            has a stain-removing effect and deep cleans;
          </li>
          <li>
            Remove microorganisms, dust, hair (using a vacuum cleaner and a
            special cleaning agent)
          </li>
        </ul>
        <p>
          As a result of the work of the Samurai Dry Cleaning mobile cleaning
          team, you will gain a healthy, restful sleep on a soft, clean, fresh
          mattress. At the same time, you don't have to take the furniture
          anywhere - we will do everything at your home, quickly and
          efficiently!
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga4() {
  const obj = {
    mainLang: 'En',
    path: '/usluga4',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga4;
