import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi5';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Corner sofa washing in Krakow</h2>
        <p>
          Corner washing is a process that requires the right technique and
          tools to effectively remove dirt, stains and bacteria. Proper cleaning
          of corners not only improves their appearance, but also extends their
          life. It is important to regularly care for upholstered furniture,
          especially those that are used intensively.
        </p>
        <p>
          Before washing the corner sofa, it is worth checking what material it
          is made of. Some fabrics require specialized cleaning products, while
          others may be more resistant to moisture. You should also remember to
          vacuum regularly to remove dust and surface contaminants.
        </p>
        <p>
          Professional corner cleaning services often use steam methods or
          special extractors that allow for deep cleaning of the fabric without
          the risk of damaging it. Regular use of such services can
          significantly improve home hygiene and the aesthetics of furniture.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga5() {
  const obj = {
    mainLang: 'En',
    path: '/usluga5',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga5;
