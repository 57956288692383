import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi1 from './SwiperUslugi';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi1 />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Pranie kanap w Krakowie</h2>
        <p>
          Czyszczenie chemiczne mebli tapicerowanych firmy Samurai Dry Cleaning
          pozwala na całkowite usunięcie brudu i nieprzyjemnego zapachu z
          materiału. Racjonalne jest czyszczenie raz w roku, aby uniknąć
          gromadzenia się brudu i kurzu w podłożu tekstylnym. Zespół firmy
          oferuje kompleksowe czyszczenie sofy, fotela, miękkiego krzesła czy
          innego rodzaju mebli przy użyciu profesjonalnego sprzętu firmy Karcher
          oraz bezpiecznych, ale skutecznych środków czyszczących. Można u nas
          zamówić również ozonowanie pomieszczeń dowolnej wielkości.
        </p>
        <h3>Jak czyścić pranie kanap w Krakowie:</h3>
        <ul>
          <li>
            Diagnoza stopnia zabrudzenia i dobór najskuteczniejszego środka do
            czyszczenia mebli;
          </li>
          <li>
            Usuwanie większych cząstek brudu i kurzu za pomocą mocnego
            odkurzacza;
          </li>
          <li>
            Usuwanie starych plam za pomocą specjalnych produktów (odplamiacze);
          </li>
          <li>Nakładanie szamponu detergentowego na tapicerkę meblową;</li>
          <li>Ostrożne rozprowadzanie składu detergentu i usuwanie plam;</li>
          <li>
            Czyszczenie systemem odsysającym i odkurzaczem wysokociśnieniowym;
          </li>
          <li>Wysuszenie</li>
        </ul>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga1',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
