import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import LinkSocial from '../../1_Header/SocialLinks/LinkSocial';
import foto from '../../../img/contact/plan.jpg';

import './Contact.css';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import MyPhone from '../../../UI/MyPhone/MyPhone';
import MyEmail from '../../../UI/MyEmail/MyEmail';
import { FormaZayavkaContactEnglish } from '../../../UI/Forma/Forma_zayavka';

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>CONTACT</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Phone number:</p>
            <MyPhone
              classphone="contac="
              icons=""
              classphonelink="phone-contact"
            />
          </div>
          <div className="contacts__item-phone">
            {/* <p>Email:</p> */}
            <MyEmail
              icons=""
              email="samurai.dry.cleaning@gmail.com"
              classnamelink="email-contact"
            ></MyEmail>
          </div>

          <div className="contacts__item-social">
            <p>We are on social networks:</p>
            <div className="contacts__item-social-links">
              <LinkSocial />
            </div>
          </div>
          <div className="contacts__item-praca">
            <img src={foto} alt="foto" />
            <p>We work 7 days a week, from 6:00 a.m. to 11:00 p.m.,</p>
            <p>and if the situation requires it, also at night.</p>
            <p>We operate in Krakow and the surrounding area.</p>
          </div>
          <div className="adress">Krakow, Poland</div>
          <FormaZayavkaContactEnglish />
        </div>
      </div>
    </div>
  );
}
function Contact() {
  const obj = {
    mainLang: 'En',
    path: '/contact',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <ContactTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Contact;
