import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>O nas</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>
              Drodzy Klienci, miło nam powitać Państwa na naszej stronie
              internetowej!
            </h2>
            <p>
              Firma <strong>"Samurai Dry Cleaning"</strong> to wiodąca firma w
              branży usług sprzątających, oferująca wysokiej jakości sprzątanie
              dla klientów komercyjnych i prywatnych. Założona z myślą o
              zapewnieniu najwyższych standardów czystości, firma szybko zdobyła
              reputację dzięki profesjonalizmowi i dbałości o szczegóły. Każdy
              pracownik Samurai Dry Cleaning przechodzi staranne szkolenie, aby
              zagwarantować skuteczne i bezpieczne sprzątanie przy użyciu
              nowoczesnego sprzętu i ekologicznych środków czystości.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              Podstawowa filozofia Softclean polega na zapewnieniu maksymalnego
              komfortu i zadowolenia klientów. Firma oferuje indywidualne
              podejście do każdego zlecenia, opracowując specjalne plany
              sprzątania, które uwzględniają wszystkie życzenia i potrzeby
              klientów. Pozwala to zapewnić nie tylko czystość, ale i zdrową
              atmosferę w pomieszczeniach.
            </p>
            <p>
              Nasza firma oferuje szeroką gamę usług, takich jak czyszczenie
              powierzchni, usuwanie plam, usuwanie nieprzyjemnych zapachów,
              dezynfekcja, odkurzanie i wiele innych. Każda usługa jest
              dostosowana do indywidualnych potrzeb klienta, aby zapewnić jak
              najlepsze efekty.
            </p>
            <p>
              Jedną z głównych zalet Samurai Dry Cleaning jest elastyczność i
              szybkość reagowania na potrzeby klientów. Firma zapewnia szybkie
              wykonanie zleceń w dogodnym dla klientów czasie, co pozwala
              zminimalizować niedogodności i przerwy w pracy. Samurai Dry
              Cleaning nieustannie doskonali swoje metody i technologie
              sprzątania, wprowadzając nowoczesne rozwiązania w celu zwiększenia
              efektywności i jakości swoich usług.
            </p>
            <p>
              <strong>Samurai Dry Cleaning</strong> dąży do tego, aby stać się
              liderem w branży sprzątającej, oferując innowacyjne i ekologiczne
              rozwiązania dla swoich klientów. Współpraca z Samurai Dry Cleaning
              to gwarancja nieskazitelnej czystości i wysokiego poziomu obsługi.
            </p>
            <p>Działamy na terenie Krakowie i okolic.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  const obj = {
    mainLang: 'PL',
    path: '/en/about',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <AboutTable />
        <WhatsApp />
        <Footer />
      </div>
    </>
  );
}
export default About;
