import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi1 from './SwiperUslugi';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi1 />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Sofa washing in Krakow</h2>
        <p>
          Dry cleaning of upholstered furniture by Samurai Dry Cleaning allows
          you to completely remove dirt and unpleasant odors from the material.
          It is rational to clean once a year to avoid dirt and dust
          accumulating in the textile base. The company's team offers
          comprehensive cleaning of sofas, armchairs, soft chairs and other
          types of furniture using professional Karcher equipment and safe but
          effective cleaning agents. You can also order ozonation of rooms of
          any size from us.
        </p>
        <h3>How to clean sofa laundry in Krakow:</h3>
        <ul>
          <li>
            Diagnosis of the degree of dirt and selection of the most effective
            furniture cleaning agent;
          </li>
          <li>
            Removing larger particles of dirt and dust with a powerful vacuum
            cleaner;
          </li>
          <li>Removing old stains using special products (stain removers);</li>
          <li>Applying detergent shampoo to furniture upholstery;</li>
          <li>
            Careful distribution of the detergent composition and removal of
            stains;
          </li>
          <li>
            Cleaning with a suction system and high-pressure vacuum cleaner;
          </li>
          <li>Drying</li>
        </ul>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  const obj = {
    mainLang: 'En',
    path: '/usluga1',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
