import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { EffectCube, Navigation } from 'swiper/modules';
import foto1 from '../../img/articles/foto13.jpg';
import foto2 from '../../img/articles/article2.jpg';
import foto3 from '../../img/articles/diwanow2.png';
import './Swiper_article.css';

function Article1() {
  return (
    <div className="block_1">
      <div className="block">
        <h3>Pranie tapicerki meblowej</h3>
      </div>
      <div>
        <img src={foto1} alt="foto1" className="fotoImg fotoImg1" />
        <p className="special-article">
          Mycie mebli tapicerowanych to jeden z najważniejszych zabiegów
          higienicznych w naszym domu i pracy. Meble tapicerowane to nie tylko
          miejsce wypoczynku, ale często także miejsce pracy. jako powierzchnia
          do jedzenia. Gromadzą zatem mnóstwo brudu, bakterii i roztoczy, które
          mogą mieć negatywny wpływ na nasze zdrowie...
        </p>
      </div>
      <div className="check">
        <Link to="/article3">Szczegółowo...</Link>
      </div>
    </div>
  );
}

function Article2() {
  return (
    <div className="block_2">
      <div className="block">
        <h3>Pranie materacy</h3>
      </div>
      <div className="articles_img-p">
        <img src={foto2} alt="foto9" className="fotoImg" />
        <p className="special-article">
          Materac jest jednym z najważniejszych elementów naszej sypialni, a
          zarazem miejscem, w którym spędzamy około jednej trzeciej naszego
          życia. Dlatego ważne jest, aby nasz materac był czysty i higieniczny.
          Regularne pranie materacy jest kluczowe dla utrzymania czystości i
          higieny, a w tym artykule przedstawimy kilka powodów, dla których jest
          to tak istotne...
        </p>
      </div>
      <div className="check">
        <Link to="/article1">Szczegółowo...</Link>
      </div>
    </div>
  );
}

function Article3() {
  return (
    <div className="block_3">
      <div className="block">
        <h3>Pranie dywanów i wykładzin</h3>
      </div>
      <div>
        <img src={foto3} alt="foto3" className="fotoImg3 fotoImg" />
        <p className="special-article">
          Pranie dywanów i wykładzin to ważna czynność, która wpływa na czystość
          i higienę naszych domów. Pomimo regularnego odkurzania, na
          powierzchniach tych mogą gromadzić się różnego rodzaju
          zanieczyszczenia, które wpływają nie tylko na estetykę, ale również na
          nasze zdrowie...
        </p>
      </div>
      <div className="check">
        <Link to="/article2">Szczegółowo...</Link>
      </div>
    </div>
  );
}
export default function SwiperArticle() {
  return (
    <>
      <Swiper
        loop={true}
        effect={'cube'}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        navigation={true}
        modules={[EffectCube, Navigation]}
        className="swiper__article"
      >
        {' '}
        <SwiperSlide>
          <Article3 />
        </SwiperSlide>
        <SwiperSlide>
          <Article1 />
        </SwiperSlide>
        {/* <SwiperSlide>
          <Article2 />
        </SwiperSlide> */}
        {/* <SwiperSlide></SwiperSlide> */}
      </Swiper>
    </>
  );
}
