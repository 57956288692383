import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';

import WhatsApp from '../../WhatsUp/WhatsappLink';

export function PriceTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Cennik usług:</h1>
        </div>
        <div className="price-padding">
          <div className="outsidewidthtable">
            <p className="lastquestion">
              <span>
                {' '}
                Ceny podane poniżej mają charakter orientacyjny. Cena
                uzależniona jest od stopnia zabrudzenia oraz ilości zamówienia.
                Ostateczną cenę można poznać dzwoniąc do nas lub wysyłając
                zdjęcie tego co wymaga czyszczenia, a nasz pracownik poda
                dokładną wycenę. Ceny są cenami brutto.
              </span>
            </p>
            <table className="table">
              <tbody>
                <tr>
                  <th className="thleft">Nazwa usług</th>
                  <th>Koszt (zl)</th>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    CENNIK PRANIA TAPICEREK MEBLOWYCH:
                  </td>
                </tr>
                <tr>
                  <td>Krzesło</td>
                  <td className="center"> od 10 do 30</td>
                </tr>
                <tr>
                  <td>Pufa</td>
                  <td className="center"> od 20 do 50</td>
                </tr>
                <tr>
                  <td>Fotel</td>
                  <td className="center">od 20 do 80</td>
                </tr>
                <tr>
                  <td>Wersalka</td>
                  <td className="center">od 80 do 200</td>
                </tr>
                <tr>
                  <td>Narożnik</td>
                  <td className="center">od 150 do 300</td>
                </tr>
                <tr>
                  <td> Materacy </td>
                  <td className="center"> od 100 do 300</td>
                </tr>
                <tr>
                  <td>Pranie pluszaków</td>
                  <td className="center"> od 10 do 200</td>
                </tr>
                <tr>
                  <td>Pranie gondoli wózków dziecięcych</td>
                  <td className="center"> od 50 do 200</td>
                </tr>
                <tr>
                  <td>Pranie fotelików samochodowych dla dzieci</td>
                  <td className="center">od 50 do 200</td>
                </tr>

                <tr>
                  <td colSpan="2" className="priceOne">
                    CENNIK PRANIA WYKŁADZIN DYWANÓW:
                  </td>
                </tr>

                <tr>
                  <td>Dywan</td>
                  <td className="center"> od 10 do 40 zł/m2</td>
                </tr>

                <tr>
                  <td>Wykładziny</td>
                  <td className="center"> od 2 do 30 zł/m2</td>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    Czyszczenie w samochodzie:
                  </td>
                </tr>
                <tr>
                  <td>Pranie podstawowe tapicerki (przednie fotele)</td>
                  <td className="center"> od 50 do 100</td>
                </tr>
                <tr>
                  <td>Pranie zaawansowane (fotele, kanapa)</td>
                  <td className="center"> od 150 do 200</td>
                </tr>
                <tr>
                  <td>
                    Pranie expert (fotele, kanapa, boczki, wykładzina,
                    podsufitka, bagażnik)
                  </td>
                  <td className="center"> od 350 do 1000</td>
                </tr>
              </tbody>
            </table>
            <p className="lastquestion">
              {' '}
              <span>Zapraszamy do skorzystania z naszych usług!</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Price() {
  const obj = {
    mainLang: 'PL',
    path: '/en/price',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <PriceTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Price;
