import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './SwiperSlider.css';
import { useRef } from 'react';
import { useProject } from '../../tools/ProviderContext';

function Guarantee() {
  return (
    <>
      <span>
        WE GUARANTEE A 100% REFUND IF THE CUSTOMER IS NOT SATISFIED WITH THE
        WASHING EFFECT!!!
      </span>
    </>
  );
}

function SliderOne() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="slider">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Washing furniture upholstery in Krakow (+30 km)
          </h2>
          <p className="slider__text-p">
            Professional furniture care, stain removal, odor removal
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Leave a request
          </button>
          <div className="slider__garantia">
            <Guarantee />
          </div>
        </div>
      </div>
    </div>
  );
}
function SliderTwo() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="sliderCar">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Detailing, washing car upholstery
          </h2>
          <p className="slider__text-p">
            We will give a second life to the interior of your car!
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Leave a request
          </button>
          <div className="slider__garantia">
            <Guarantee />
          </div>
        </div>
      </div>
    </div>
  );
}
function SliderThree() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className=" sliderKids">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Washing baby strollers and baby seats
          </h2>
          <p className="slider__text-p">
            {' '}
            Taking care of your child's health!{' '}
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Leave a request
          </button>
          <div className="slider__garantia">
            <Guarantee />
          </div>
        </div>
      </div>
    </div>
  );
}
function Slider4() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className=" sliderMatress">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">Washing mattresses</h2>
          <p className="slider__text-p">
            We will remove contaminants such as dust, mites, mold and bacteria
            that accumulate in the mattress over time.
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Leave a request
          </button>
          <div className="slider__garantia">
            <Guarantee />
          </div>
        </div>
      </div>
    </div>
  );
}
function Slider5() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="sliderRug">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">Washing carpets and rugs</h2>
          <p className="slider__text-p">
            {' '}
            Removal of dust, dirt, animal hair and hair.
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Leave a request
          </button>
          <div className="slider__garantia">
            <Guarantee />
          </div>
        </div>
      </div>
    </div>
  );
}

function SwiperSliders() {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <Swiper
      loop={true}
      spaceBetween={0}
      navigation
      centeredSlides={true}
      slidesPerView={'auto'}
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      modules={[EffectCoverflow, Autoplay, Navigation]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      <SwiperSlide>
        <SliderOne />
      </SwiperSlide>
      <SwiperSlide>
        <SliderTwo />
      </SwiperSlide>
      <SwiperSlide>
        <SliderThree />
      </SwiperSlide>
      <SwiperSlide>
        <Slider4 />
      </SwiperSlide>
      <SwiperSlide>
        <Slider5 />
      </SwiperSlide>
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  );
}
export default SwiperSliders;
