import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './ListUslugi.css';

function LineUnderText() {
  return <div className="uslugi__line-under"></div>;
}
function UslugiList() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);
  return (
    <>
      <div className="uslugi-list">
        <span>Uslugi</span>
        <ul>
          <Link onClick={() => setScrollUp(true)} to="/usluga1">
            <li>Pranie kanap</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga2">
            <li>Tapicerka samochodowa</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga3">
            <li>Pranie foteli</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga4">
            <li>Pranie materaców</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga5">
            <li>Pranie narożników</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga6">
            {' '}
            <li>Pranie chemiczne wózków i fotelików samochodowych</li>
            <LineUnderText />
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/usluga7">
            {' '}
            <li>Czyszczenie tapicerki skórzanej</li>
            <LineUnderText />
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/usluga8">
            {' '}
            <li>Pranie dywanów i wykładzin</li>
            <LineUnderText />
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/usluga9">
            {' '}
            <li>Pranie zasłon, firan, rolet</li>
          </Link>{' '}
        </ul>
      </div>
    </>
  );
}
export function UslugiListEn() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);
  return (
    <>
      <div className="uslugi-list">
        <span>Services</span>
        <ul>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga1">
            <li>Washing couches</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga2">
            <li>Car upholstery</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga3">
            <li>Washing armchairs</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga4">
            <li>Washing mattresses</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga5">
            <li>Corner washing</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga6">
            {' '}
            <li>Dry cleaning of strollers and car seats</li>
            <LineUnderText />
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/en/usluga7">
            {' '}
            <li>Cleaning leather upholstery</li>
            <LineUnderText />
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/en/usluga8">
            {' '}
            <li>Washing carpets and rugs</li>
            <LineUnderText />
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/en/usluga9">
            {' '}
            <li>Washing curtains, curtains and blinds</li>
          </Link>{' '}
        </ul>
      </div>
    </>
  );
}
export default UslugiList;
