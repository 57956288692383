import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/profile.png';
import burning from '../../../img/important/burning.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Dlaczego właśnie my!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={burning} alt="expert" className="expert" />
          <h3>Szybkie terminy</h3>
          <p>
            Przyjedziemy do prania tapicerki meblowej jutro albo nawet dzisiaj w
            dogodnym dla Państwa terminie.
          </p>
        </div>
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Profesjonalizm</h3>
          <p>
            Odpowiedzialnie podchodzimy do realizacji zadań i zapewniamy
            efektywną współpracę z naszymi klientami i partnerami.
          </p>
        </div>
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Jakość</h3>
          <p>
            Wszystkie środki chemiczne, których używamy są całkowicie
            nieszkodliwe dla ludzi i zwierząt, posiadają wszelkie niezbędne
            certyfikaty zgodności, a nasz profesjonalny sprzęt wiodących marek
            takich jak Karcher (Niemcy) gwarantuje wysoką jakość pracy.
          </p>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Indywidualne podejście</h3>
          <p>
            Zapewniamy indywidualne podejście do każdego klienta, starannie
            analizując jego potrzeby i proponując optymalne rozwiązania, aby w
            pełni zaspokoić jego oczekiwania.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Important;
