import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi3';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Washing armchairs in Krakow</h2>
        <p>
          Dry cleaning the armchair is an important step in furniture care,
          which helps maintain its neat appearance and extend its life. During
          everyday use, the armchairs accumulate dust, stains and bacteria,
          which not only spoils their appearance, but may also cause allergic
          reactions. Professional dry cleaning effectively removes contamination
          using special agents that do not damage the material, leaving the
          furniture fresh and clean.
        </p>
        <h3>The chemical cleaning process consists of several stages:</h3>
        <p>
          The chair dry cleaning process consists of several carefully carried
          out stages to ensure the best possible results. First, the chair is
          pre-cleaned to remove larger dirt such as dust and loose debris. Then,
          special cleaning agents are applied to the upholstery surface, which
          are selected depending on the type of material - different
          preparations are used for fabrics, and others for leather or suede.
          These agents penetrate deep into the fabric, dissolving stains and
          removing bacteria. After this stage, excess moisture and dirt residues
          are effectively removed using special devices, thanks to which the
          chair not only looks fresh, but also dries quickly.
        </p>
        <p>
          The result of such cleaning is not only the aesthetic refreshment of
          furniture, but also improvement of hygiene in the living space.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga3() {
  const obj = {
    mainLang: 'En',
    path: '/usluga3',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga3;
