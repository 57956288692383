import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi6';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Dry cleaning of strollers and car seats in Krakow</h2>
        <p>
          One of the most popular services is dry cleaning of strollers and car
          seats in Krakow. We understand that it is important for you to keep
          your child clean and safe, which is why we use the latest technologies
          and safe recipes to achieve great results.
        </p>
        <h3>Our advantages:</h3>
        <ul>
          <li>
            Harmlessness comes first: we guarantee that all the chemicals and
            methods we use are completely harmless. Our craftsmen have extensive
            experience in working with children's products and understand how
            necessary it is to avoid harmful effects on children's health. We
            use neutral detergents and a steam generator that primarily kills
            bacteria and microorganisms without the use of chemicals.
          </li>
          <li>
            effective removal of stains and odors: Our specialists are equipped
            with modern equipment and professional products that effectively
            remove even the most difficult stains and unpleasant odors. The
            entire cleaning process is done manually. It is not trivial washing
            trolleys at a car wash using Karcher, but tedious physical work. As
            a result of cleaning, your stroller or car seat will not only be
            spotlessly clean, but will also smell nice.
          </li>
          <li>
            Long-term preservation of things: Processing technology not only
            ensures the freshness of products, but also extends their service
            life. During the cleaning process, the product is completely
            disassembled. We approach every detail carefully, paying attention
            to every fold and seam to maintain vibrant colors, prevent wear and
            tear and maintain optimal hygiene standards. The frame, wheels and
            all plastic chassis elements are thoroughly washed. Assembly is
            carried out strictly according to the manufacturers' instructions to
            avoid possible traumatic situations during operation.
          </li>
          <li>
            Professional Team: Our car seat cleaning experts have extensive
            skills in working with baby products. Hundreds of models from
            leading global manufacturers have passed through our hands, such as
            Cybex, Bair, Anex, Espiro, Carrello, El Camino, KinderKraft,
            Lionelo, etc. We know all the intricacies of the analysis and
            specifics of cleaning each material. We pay attention to details, we
            do our work conscientiously and we are ready to provide your little
            one with maximum comfort and cleanliness.
          </li>
        </ul>
        <p>
          Don't forget that dry cleaning of car seats and strollers is primarily
          about your child's health.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga6() {
  const obj = {
    mainLang: 'En',
    path: '/usluga6',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga6;
