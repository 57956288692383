import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';

import WhatsApp from '../../WhatsUp/WhatsappLink';

export function PriceTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Price list of services:</h1>
        </div>
        <div className="price-padding">
          <div className="outsidewidthtable">
            <p className="lastquestion">
              <span>
                The prices given below are indicative. The price depends on the
                degree of dirt and the quantity of the order. You can find out
                the final price by calling us or sending a photo of what needs
                cleaning, and our employee will give you an accurate quote.
                Prices are gross prices.
              </span>
            </p>
            <table className="table">
              <tbody>
                <tr>
                  <th className="thleft">Name of the services</th>
                  <th>Cost (PLN)</th>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    FURNITURE UPHOLSTERY WASHING PRICE LIST:
                  </td>
                </tr>
                <tr>
                  <td>Chair</td>
                  <td className="center"> from 10 to 30</td>
                </tr>
                <tr>
                  <td>Pufa</td>
                  <td className="center"> from 20 to 50</td>
                </tr>
                <tr>
                  <td>Armchair</td>
                  <td className="center">from 20 to 80</td>
                </tr>
                <tr>
                  <td>Couch</td>
                  <td className="center">from 80 to 200</td>
                </tr>
                <tr>
                  <td>Corner</td>
                  <td className="center">from 150 to 300</td>
                </tr>
                <tr>
                  <td> Mattresses </td>
                  <td className="center"> from 100 to 300</td>
                </tr>
                <tr>
                  <td>Washing stuffed animals</td>
                  <td className="center"> from 10 to 200</td>
                </tr>
                <tr>
                  <td>Washing carrycots for strollers</td>
                  <td className="center"> from 50 to 200</td>
                </tr>
                <tr>
                  <td>Washing children's car seats</td>
                  <td className="center">from 50 to 200</td>
                </tr>

                <tr>
                  <td colSpan="2" className="priceOne">
                    CARPET WASHING PRICE LIST:
                  </td>
                </tr>

                <tr>
                  <td>Rug</td>
                  <td className="center"> from 10 to 40 zł/m2</td>
                </tr>

                <tr>
                  <td>Carpets</td>
                  <td className="center"> from 2 to 30 zł/m2</td>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    Cleaning in the car:
                  </td>
                </tr>
                <tr>
                  <td>Advanced washing (armchairs, sofa)</td>
                  <td className="center"> from 50 do 100</td>
                </tr>
                <tr>
                  <td>Advanced washing (armchairs, sofa)</td>
                  <td className="center">from 150 to 200</td>
                </tr>
                <tr>
                  <td>
                    Expert laundry (seats, sofa, sides, carpet, headliner,
                    trunk)
                  </td>
                  <td className="center"> od 350 to 1000</td>
                </tr>
              </tbody>
            </table>
            <p className="lastquestion">
              <span>We invite you to use our services!</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Price() {
  const obj = {
    mainLang: 'En',
    path: '/price',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <PriceTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Price;
