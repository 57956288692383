import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi8';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Pranie dywanów i wykładzin w Krakowie</h2>
        <p>
          Czyszczenie chemiczne dywanów i wykładzin to ważny proces, który
          pomaga utrzymać czystość i higienę w domu. Z biegiem czasu w włóknach
          gromadzą się kurz, brud, sierść zwierząt i inne zanieczyszczenia,
          których nie zawsze można się pozbyć podczas codziennego sprzątania.
          Profesjonalne czyszczenie skutecznie usuwa te cząstki, poprawiając
          jakość powietrza w pomieszczeniu. Dywany wymagają okresowego
          czyszczenia, gdyż w trakcie użytkowania gromadzą się w nich kurz,
          brud, sierść zwierząt i sierść. Samurai Dry Clean ułatwia ten proces,
          oferując usługi czyszczenia dywanów na miejscu. Oznacza to, że nie
          będziesz musiał sam zabierać dużego dywanu do prania, nosić go po
          podeście i tak dalej.
        </p>
        <h3>Czyszczenie chemiczne dywanów o dowolnej złożoności</h3>
        <ul>
          <li>
            Właściciele takich powłok spotykają się z różnego rodzaju
            zanieczyszczeniami. Mogą to być plamy z jedzenia i napojów, mocz
            zwierząt lub brud z butów. Rozsądnie jest czyścić co kilka miesięcy,
            aby zmniejszyć liczbę roztoczy i zminimalizować ryzyko alergii u
            dzieci i dorosłych.
          </li>
          <li>
            Czyszczenie chemiczne dywanów na miejscu nie marnuje czasu klienta.
            Proces namaczania, obróbki powierzchni, odkurzania brudu i końcowego
            mycia nie zajmuje dużo czasu. Można to zrobić o każdej porze roku i
            przy każdej pogodzie.
          </li>
        </ul>
        <p>
          Regularne czyszczenie chemiczne dywanów nie tylko przedłuża ich
          żywotność, ale także pomaga utrzymać estetyczny wygląd wnętrza.
          Oczyszczone dywany wyglądają świeżo, zachowują intensywność kolorów i
          tworzą przytulną atmosferę w domu lub biurze.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga8() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga8',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga8;
