import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi9';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Pranie zasłon, firan, rolet w Krakowie</h2>
        <p>
          Pranie zasłon, firan i rolet to często pomijana czynność w utrzymaniu
          czystości w naszych domach. Jednakże, jest to bardzo ważna czynność,
          której nie powinniśmy ignorować. Pranie tych elementów wyposażenia
          okien to nie tylko kwestia estetyki, ale przede wszystkim zdrowia.
          Poniżej przedstawiamy kilka powodów, dla których pranie zasłon, firan
          i rolet jest tak ważne.
        </p>
        <p>
          Po pierwsze, zasłony, firany i rolety gromadzą wiele kurzu, pyłków i
          innych zanieczyszczeń, które mogą powodować problemy z układem
          oddechowym. Wiele osób cierpi na alergie i astmę, a takie
          zanieczyszczenia mogą wpływać negatywnie na ich zdrowie. Regularne
          pranie tych elementów może pomóc w usunięciu zanieczyszczeń i poprawić
          jakość powietrza w naszych domach.
        </p>
        <p>
          Po drugie, zasłony, firany i rolety są narażone na zanieczyszczenia z
          otoczenia. Mogą to być m.in. spaliny, dym papierosowy, czy też zapachy
          z kuchni. Jeśli nie będziemy regularnie prać tych elementów, to
          zapachy te będą utrzymywać się na zasłonach, firanach i roletach, co
          może wpłynąć negatywnie na jakość powietrza w naszych domach.
        </p>
        <p>
          Po trzecie, pranie zasłon, firan i rolet może pomóc w utrzymaniu ich
          pierwotnego wyglądu. Te elementy wyposażenia okien często są narażone
          na działanie promieni słonecznych, co może powodować blaknięcie
          kolorów i uszkodzenia tkanin. Regularne pranie może pomóc w utrzymaniu
          ich kolorów i faktury na dłużej.
        </p>
        <p>
          Warto również zwrócić uwagę, że pranie zasłon, firan i rolet jest
          korzystne nie tylko dla naszego zdrowia, ale także dla naszych
          portfeli. Jeśli będziemy regularnie prać te elementy, to będą one
          trwalsze i nie będziemy musieli tak często wymieniać ich na nowe.
        </p>
        <p>
          Podsumowując, pranie zasłon, firan i rolet to ważna czynność, której
          nie powinniśmy pomijać w utrzymaniu czystości w naszych domach. Pomaga
          ona w usuwaniu zanieczyszczeń, poprawie jakości powietrza oraz
          utrzymaniu pierwotnego wyglądu tkanin. Regularne pranie tych elementów
          jest korzystne zarówno dla naszego zdrowia, jak i dla naszych
          portfeli.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga9() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga9',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga9;
